import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './Footer.css';
import logo from '../images/logo.png'; // Import the logo image

const Footer = () => {
  const handleGetListed = () => {
    const emailSubject = 'I want to add my photography studio';
    const emailBody = `Hi,

I want to add my photography studio to your website. Please let me know the next steps to get listed.

Thank you,
[Your Name]`;

    const mailtoLink = `mailto:lead@bumpandbabyportraits.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="footer-logo" />
          </div>
          <div className="contact-info">
            8019 Menaul Blvd NE<br />
            Albuquerque, New York, USA 87110<br />
            Phone: (505) 299-0001<br />
            Email: info@photographydirectory.com
          </div>
          <div className="social-media-links">
            {/* Add social media profile icon links here */}
          </div>
        </div>
        <div className="footer-center">
          <h4>Top Locations</h4>
          <ul>
            <li>
              <Link to="/new-york">New York</Link>
            </li>
            <li>
              <Link to="/los-angeles">Los Angeles</Link>
            </li>
            <li>
              <Link to="/chicago">Chicago</Link>
            </li>
            <li>
              <Link to="/miami">Miami</Link>
            </li>
            <li>
              <Link to="/houston">Houston</Link>
            </li>
          </ul>
        </div>
        <div className="footer-right">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Button type="link" onClick={handleGetListed}>
                Get Listed
              </Button>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;