import React, { useState } from 'react';
import { Box, Typography, Container, Grid, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the form data to the specified email address
    const emailBody = `
      Name: ${formData.name}
      Email: ${formData.email}
      Message: ${formData.message}
    `;
    const mailtoLink = `mailto:harikezdigital@gmail.com?subject=Contact Form Submission&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;

    // Reset the form fields
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <StyledContainer maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box textAlign="center">
            <StyledHeading variant="h2" gutterBottom>
              Contact Us
            </StyledHeading>
            <StyledParagraph variant="body1">
              We'd love to hear from you! If you have any questions, feedback, or inquiries, please feel free to reach out to us using the contact form below. Our team will get back to you as soon as possible.
            </StyledParagraph>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledHeading variant="h4">Contact Information</StyledHeading>
          <StyledParagraph variant="body1">
          8019 Menaul Blvd NE<br />
          Albuquerque,New York, USA 87110<br />
            Phone: (505) 299-0001<br />
            Email: info@photographydirectory.com
          </StyledParagraph>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledHeading variant="h4">Send Us a Message</StyledHeading>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="center">
            <StyledParagraph variant="body1">
              Thank you for your interest in our photography directory listing site. We appreciate your feedback and will respond to your message as soon as possible.
            </StyledParagraph>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default ContactUs;