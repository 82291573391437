import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Breadcrumbs, Typography } from '@mui/material';
import { businesses, categories } from '../data/data';
import { Container, Grid, Card, CardMedia, CardContent, Button, Box, TextField, MenuItem, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/system';
import { Phone as PhoneIcon, Language as LanguageIcon, CheckCircle as CheckCircleIcon, Shield as ShieldIcon, Chat as ChatIcon } from '@mui/icons-material';
import './BusinessList.css';
import { createTheme } from '@mui/material/styles';

// Import the images
import nb1 from '../images/nb1.jpg';
import nb2 from '../images/nb2.jpg';
import nb3 from '../images/nb3.jpg';
import nb4 from '../images/nb4.jpg';
import nb5 from '../images/nb5.jpg';
import nb6 from '../images/nb6.jpg';
import nb7 from '../images/nb7.jpg';
import nb8 from '../images/nb8.jpg';
import nb9 from '../images/nb9.jpg';
import nb10 from '../images/nb10.jpg';
import nb11 from '../images/nb11.jpg';
import nb12 from '../images/nb12.jpg';
import nb13 from '../images/nb13.jpg';

import m1 from '../images/m1.jpg';
import m2 from '../images/m2.jpg';
import m3 from '../images/m3.jpg';
import m4 from '../images/m4.jpg';
import m5 from '../images/m5.jpg';
import m6 from '../images/m6.jpg';
import m7 from '../images/m7.jpg';
import m8 from '../images/m8.jpg';
import m9 from '../images/m9.jpg';
import m10 from '../images/m10.jpg';
import m11 from '../images/m11.jpg';
import m12 from '../images/m12.jpg';

import f1 from '../images/f1.jpg';
import f2 from '../images/f2.jpg';
import f3 from '../images/f3.jpg';
import f4 from '../images/f4.jpg';
import f5 from '../images/f5.jpg';
import f6 from '../images/f6.jpg';
import f7 from '../images/f7.jpg';
import f8 from '../images/f8.jpg';
import f9 from '../images/f9.jpg';
import f10 from '../images/f10.jpg';
import f11 from '../images/f11.jpg';
import f12 from '../images/f12.jpg';

const newBornPhotographerImages = [
  nb1, nb2, nb3, nb4, nb5, nb6, nb7, nb8, nb9, nb10, nb11, nb12, nb13,
];

const maternityPhotographerImages = [
  m1, m2, m3, m4, m5, m6, m7, m8, m9, m10, m11, m12,
];

const familyPhotographerImages = [
  f1, f2, f3, f4, f5, f6, f7, f8, f9, f10, f11, f12,
];


const customTheme = createTheme({
  palette: {
    custom: {
      main: '#283618',
    },
  },
});

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(4),
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '40%',
  height: '100%',
  objectFit: 'cover',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '25px',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const StyledFeatureBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: 'inline-block',
}));

const StyledTrustScoreBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledQuickQuoteButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: customTheme.palette.custom.main, // Use the custom color
  color: theme.palette.common.white, // Set text color to white
  '&:hover': {
    backgroundColor: customTheme.palette.custom.main, // Use the custom color for hover state
  },
  borderRadius: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
}));

const BusinessList = () => {
  const { location, category } = useParams();
  const [selectedLocation, setSelectedLocation] = useState(location);
  const [selectedType, setSelectedType] = useState('');
  const [selectedHours, setSelectedHours] = useState(1);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [openQuoteDialog, setOpenQuoteDialog] = useState(false);
  const [quoteFormData, setQuoteFormData] = useState({
    businessName: '',
    locationName: '',
    name: '',
    email: '',
    phone: '',
    pageUrl: window.location.href,

  });
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleHoursChange = (event) => {
    setSelectedHours(event.target.value);
  };

  const calculateCost = () => {
    let baseCost = 0;
    if (selectedHours === 1) {
      baseCost = Math.floor(Math.random() * 21) + 150;
    } else if (selectedHours === 2) {
      baseCost = Math.floor(Math.random() * 41) + 300;
    } else if (selectedHours === 3) {
      baseCost = Math.floor(Math.random() * 61) + 450;
    }
    setEstimatedCost(baseCost);
  };


  const formatCategoryName = (category) => {
    return category.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const formatLocationName = (location) => {
    return location.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const filteredBusinesses = businesses
  .filter(
    (business) =>
      business.location && location &&
      business.location.toLowerCase().replace(/\s+/g, '-') === location.toLowerCase()
  )
  .filter(
    (business) =>
      !category ||
      business.category?.toLowerCase() === category.toLowerCase() ||
      (business.categories && business.categories.some((cat) => cat.toLowerCase() === category.toLowerCase()))
  )
  .sort((a, b) => {
    if ((category || '') === 'newborn-photography') {
      return a.id - b.id; // Ascending order for newborn-photography
    } else if ((category || '') === 'maternity-photography') {
      return b.id - a.id; // Descending order for maternity-photography
    } else {
      return a.id % 2 === 1 ? -1 : 1; // Odd indices first for family-photography
    }
  });

  // Select the image array based on the category
  // Select the image array based on the category
  let imagePool = [];
  if (category.toLowerCase() === 'newborn-photography') {
    imagePool = [...newBornPhotographerImages];
  } else if (category.toLowerCase() === 'maternity-photography') {
    imagePool = [...maternityPhotographerImages];
  } else if (category.toLowerCase() === 'family-photography') {
    imagePool = [...familyPhotographerImages];
  }

  // Shuffle the image pool to randomize the order
  for (let i = imagePool.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [imagePool[i], imagePool[j]] = [imagePool[j], imagePool[i]];
  }

  const handleQuickQuote = (businessName) => {
    setQuoteFormData((prevData) => ({
      ...prevData,
      businessName,
      locationName: formatLocationName(location),
    }));
    setOpenQuoteDialog(true);
  };

  const handleQuoteFormChange = (event) => {
    const { name, value } = event.target;
    setQuoteFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleQuoteFormSubmit = () => {
    // Send the quote form data to the specified email address
    const emailBody = `
      Business Name: ${quoteFormData.businessName}
      Location Name: ${quoteFormData.locationName}
      Name: ${quoteFormData.name}
      Email: ${quoteFormData.email}
      Phone: ${quoteFormData.phone}
      Page URL: ${quoteFormData.pageUrl}
    `;
    const mailtoLink = `ailto:lead@bumpandbabyportraits.com?subject=Quick Quote Request&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
    setOpenQuoteDialog(false);
  };
  console.log('Location:', location);
  console.log('Category:', category);
  console.log('Filtered Businesses:', filteredBusinesses);

  return (
    
       <StyledContainer>
     <Helmet>
  <title>{`Top ${filteredBusinesses.length} ${formatCategoryName(category)} in ${formatLocationName(location)} [Last Updated ${new Date().toLocaleString('default', { month: 'long' })} ${new Date().getFullYear()}]`}</title>
  <meta
    name="description"
    content={`Find the top ${filteredBusinesses.length} ${formatCategoryName(category)} photographers in ${formatLocationName(location)} for your maternity, newborn, or baby photo session. Our verified photographers offer exceptional services at affordable prices. Browse through their portfolios, read reviews, and get instant quotes to book your photo session today. Last updated in ${new Date().toLocaleString('default', { month: 'long' })} ${new Date().getFullYear()}.`}
  />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Bump & Baby Photography",
      "url": "https://bumpandbabyportrait.com",
      "logo": "https://bumpandbabyportrait.com/logo.png",
      "description": "Bump & Baby Photography is a leading photography directory connecting clients with talented photographers specializing in maternity, newborn, and baby photography.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "8019 Menaul Blvd NE ",
        "addressLocality": "Albuquerque",
        "addressRegion": "New York ",
        "postalCode": "87110",
        "addressCountry": "United States"
      },
      "telephone": "(505) 299-0001",
      "email": "info@bumpandbabyportrait.com"
    })}
  </script>
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://bumpandbabyportrait.com"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": formatLocationName(location),
          "item": `https://bumpandbabyportrait.com/${location}`
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": formatCategoryName(category),
          "item": `https://bumpandbabyportrait.com/${location}/${category}`
        }
      ]
    })}
  </script>
</Helmet>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
        <Link to="/">Home</Link>
        <Link to={`/${location}`}>{formatLocationName(location)}</Link>
        <Typography color="text.primary">{formatCategoryName(category)}</Typography>
      </Breadcrumbs>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h4" align="center" gutterBottom>
                Best {formatCategoryName(category)} in {formatLocationName(location)}
              </Typography>
              <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
  Last Updated: {new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString()}
</Typography>
              <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                We have reviewed {filteredBusinesses.length * 10}+ {formatCategoryName(category)} in {formatLocationName(location)} and filtered the best ones for you.
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Box display="flex" alignItems="center" mr={2}>
                  <CheckCircleIcon color="primary" />
                  <Typography variant="body2" ml={1}>
                    Reviewed by experts
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mr={2}>
                  <ShieldIcon color="primary" />
                  <Typography variant="body2" ml={1}>
                    100% verified businesses
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <ChatIcon color="primary" />
                  <Typography variant="body2" ml={1}>
                    Call/message directly
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
  
          <Box mt={4}>
  {filteredBusinesses.map((business, index) => {
    if (
      (category === 'newborn-photography') ||
      (category === 'maternity-photography' && index < 8) ||
      category === 'family-photography'
    ) {
      return (
        <StyledCard key={business.id}>
          <StyledCardMedia
  component="img"
  image={imagePool[index % imagePool.length]}
  alt={business.name}
/>
          <StyledCardContent>
            <Typography variant="h5" gutterBottom>
              #{index + 1} {business.name}
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<LanguageIcon />}
                onClick={() => window.open(business.website, '_blank')}
              >
                Visit Website
              </Button>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PhoneIcon />}
                href={`tel:${business.phone}`}
              >
                Call Now
              </Button>
            </Box>
            <Typography variant="body2" color="textSecondary">
              {business.detailedAddress}
            </Typography>
            <Box mt={2}>
              {business.features.map((feature) => (
                <StyledFeatureBox key={feature}>{feature}</StyledFeatureBox>
              ))}
            </Box>
            <StyledTrustScoreBox>
              <Typography variant="body1">
                Our Trust Score: {business.trustScore} Rated By Expert
              </Typography>
            </StyledTrustScoreBox>
            <StyledQuickQuoteButton variant="contained" onClick={() => handleQuickQuote(business.name)}>
              Get Quick Quote
            </StyledQuickQuoteButton>
          </StyledCardContent>
        </StyledCard>
      );
    }
    return null;
  })}
</Box>  
          <hr />
  
          <Box mt={4} textAlign="center">
            <Typography variant="h2" gutterBottom>
              How much does it cost to hire a {formatCategoryName(category)} in {formatLocationName(location)}?
            </Typography>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      label="Choose your location"
                      value={selectedLocation}
                      onChange={handleLocationChange}
                      fullWidth
                    >
                      {/* Add location options */}
                      <MenuItem value="new-york">New York</MenuItem>
                      <MenuItem value="los-angeles">Los Angeles</MenuItem>
                      <MenuItem value="chicago">Chicago</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      label="Select your type"
                      value={selectedType}
                      onChange={handleTypeChange}
                      fullWidth
                    >
                      <MenuItem value="indoor">Indoor</MenuItem>
                      <MenuItem value="outdoor">Outdoor</MenuItem>
                      <MenuItem value="studio">Studio</MenuItem>
                      <MenuItem value="home">Home</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      label="Choose hours"
                      value={selectedHours}
                      onChange={handleHoursChange}
                      fullWidth
                    >
                      <MenuItem value={1}>1 Hour</MenuItem>
                      <MenuItem value={2}>2 Hours</MenuItem>
                      <MenuItem value={3}>3 Hours</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" onClick={calculateCost} fullWidth>
                      Calculate
                    </Button>
                  </Grid>
                </Grid>
                {estimatedCost > 0 && (
                  <Typography variant="body1" align="center" mt={2}>
                    In {formatLocationName(selectedLocation)}, a {formatCategoryName(category)} photoshoot typically costs around ${estimatedCost}.
                  </Typography>
                )}
              </CardContent>
            </Card>
            <Box mt={2}>
              <Button variant="contained" color="secondary">
                Get a Quick Quote
              </Button>
            </Box>
          </Box>
  
          <hr />
  
          <Box mt={4}>
            <Typography variant="h2" align="center" gutterBottom>
              Going to Hire a {formatCategoryName(category)} in {formatLocationName(location)}? Here's Your Checklist
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      1. Check Portfolio
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Review the photographer's portfolio to ensure their style aligns with your preferences.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      2. Discuss Pricing
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Discuss pricing and packages with the photographer to ensure it fits your budget.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      3. Check Availability
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Confirm the photographer's availability for your desired date and time.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      4. Read Reviews
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Read reviews and testimonials from previous clients to gauge their experience.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      5. Sign a Contract
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Ensure you have a written contract outlining the terms of the photoshoot.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
  
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Get Quick Quotes from Top 3 Photographers
              </Typography>
              <Box mb={2}>
                <TextField label="Name" variant="outlined" fullWidth margin="normal" />
                <TextField label="Email" variant="outlined" fullWidth margin="normal" />
                <TextField label="Message" variant="outlined" fullWidth multiline rows={4} margin="normal" />
              </Box>
              <Button variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </CardContent>
          </Card>
  
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Other Photographers in {formatLocationName(location)}
            </Typography>
            {categories.map((cat) => (
              <Box key={cat} mb={2}>
                <Card>
                  <CardContent>
                    <Link to={`/${location.toLowerCase().replace(/ /g, '-')}/${cat.toLowerCase().replace(/ /g, '-')}`}>
                      <Typography variant="body2">
                        {formatCategoryName(cat)} in {formatLocationName(location)}
                      </Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
  
      <Dialog open={openQuoteDialog} onClose={() => setOpenQuoteDialog(false)}>
        <DialogTitle>Get Quick Quote</DialogTitle>
        <DialogContent>
          <TextField
            label="Business Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={quoteFormData.businessName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Location Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={quoteFormData.locationName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="name"
            value={quoteFormData.name}
            onChange={handleQuoteFormChange}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
            value={quoteFormData.email}
            onChange={handleQuoteFormChange}
          />
          <TextField
            label="Phone"
            variant="outlined"
            fullWidth
            margin="normal"
            name="phone"
            value={quoteFormData.phone}
            onChange={handleQuoteFormChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQuoteDialog(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleQuoteFormSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};


export default BusinessList;