import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import LocationList from './components/LocationList';
import CategoryList from './components/CategoryList';
import BusinessList from './components/BusinessList';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff5722',
    },
    grey: {
      200: '#eeeeee',
      // Define other grey shades as needed
    },
  },
  // Other theme customizations
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/locations" element={<LocationList />} />
            <Route path="/:location" element={<CategoryList />} />
            <Route path="/:location/:category" element={<BusinessList />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;