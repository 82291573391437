import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Typography, Card } from 'antd';
import './CategoryList.css';
import { Helmet } from 'react-helmet';
import { categories } from '../data/data';

const { Title } = Typography;

const formatCategoryName = (category) => {
  return category ? category.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '';
};

const CategoryList = () => {
  const { location } = useParams();

  return (
    <div className="category-list">
      <Helmet>
        <title>Photography Categories | Maternity, Newborn & More | Get Quotes in 3 Sec</title>
        <meta name="description" content="Discover stunning photography services for your maternity, newborn, and baby photo sessions. Our carefully selected categories showcase the best photographers who specialize in capturing the precious moments of your journey. Get instant quotes and book your photo session in just 3 seconds." />
      </Helmet>

      <div className="categories-section">
        <Title level={2}>Photography Categories</Title>
        <Row gutter={[16, 16]}>
          {categories.map((category) => (
            <Col xs={24} sm={12} md={8} key={category}>
              <Link to={`/${location.toLowerCase().replace(/\s+/g, '-')}/${category.toLowerCase().replace(/\s+/g, '-')}`}>
                <Card className="category-card">
                  <div className="category-name">{formatCategoryName(category)}</div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default CategoryList;