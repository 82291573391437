import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './Header.css';
import logo from '../images/logo.png'; // Import the logo image

const Header = () => {
  const handleGetListed = () => {
    const emailSubject = 'I want to add my photography studio';
    const emailBody = `Hi,

I want to add my photography studio to your website. Please let me know the next steps to get listed.

Thank you,
[Your Name]`;

    const mailtoLink = `mailto:harikezdigital@gmail.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <nav className="navigation">
        <ul>
          <li>
            <Link to="/locations">Locations</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Button type="primary" className="get-listed-button" onClick={handleGetListed}>
              Get Listed
            </Button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;