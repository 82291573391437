import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Input, Select, Carousel, Typography, Layout, Space, Image, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faPhone as fasPhone, faExternalLinkAlt, faMapMarkerAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import './Home.css';
import { businesses } from '../data/data';
import nb1 from '../images/nb1.jpg';
import nb2 from '../images/nb2.jpg';
import nb3 from '../images/nb3.jpg';
import nb4 from '../images/nb4.jpg';
import nb5 from '../images/nb5.jpg';
import nb6 from '../images/nb6.jpg';
import nb7 from '../images/nb7.jpg';
import nb8 from '../images/nb8.jpg';
import nb9 from '../images/nb9.jpg';
import nb10 from '../images/nb10.jpg';
import nb11 from '../images/nb11.jpg';
import nb12 from '../images/nb12.jpg';
import nb13 from '../images/nb13.jpg';

const { Option } = Select;
const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

const Home = () => {
  const imagePool = [nb1, nb2, nb3, nb4, nb5, nb6, nb7, nb8, nb9, nb10, nb11, nb12, nb13];

  const newestPhotographers = businesses.slice(0, 6);

  for (let i = imagePool.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [imagePool[i], imagePool[j]] = [imagePool[j], imagePool[i]];
  }
  const handleNewsletterSubmit = (values) => {
    const emailBody = `
      Email: ${values.email}
      Page URL: ${window.location.href}
    `;
    const mailtoLink = `mailto:lead@bumpandbabyportraits.com?subject=Newsletter Subscription&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
  };
  const locations = [
    'New York',
    'Los Angeles',
    'Chicago',
    'Miami',
    'Houston',
    'Dallas',
    'Philadelphia',
    'Atlanta',
    'Washington',
    'Boston',
    'Phoenix',
    'Detroit',
    'Seattle',
    'San Francisco',
    'San Diego',
    'Minneapolis',
    'Tampa',
    'Brooklyn',
    'Denver',
    'Queens',
    'Riverside',
    'Las Vegas',
    'Baltimore',
    'St. Louis',
  ];

  const reviews = [
    {
      id: 1,
      name: 'Eliz',
      photo: 'https://i.ibb.co/Xttdr1P/undefined.jpg',
      review: 'I got booked from bumpandbabyportraits.com and had a great discount. The photographer was very expert and captured amazing moments.',
      rating: 5,
    },
    {
      id: 2,
      name: 'Jaith',
      photo: 'https://i.ibb.co/WkkZR0J/image-17399-3545089.jpg',
      review: 'The photographer I hired through this website was talented and professional. The photos turned out stunning!',
      rating: 4,
    },
    {
      id: 3,
      name: 'Meghn',
      photo: 'https://i.ibb.co/bFZsSTz/image-17389-3545089.jpg',
      review: 'Thanks to bumpandbabyportraits.com, I found the perfect photographer for my maternity shoot. The experience was wonderful!',
      rating: 5,
    },
  ];

  const featuredPackages = [
    {
      id: 1,
      name: 'Basic Package',
      description: 'Includes 1-hour photoshoot and 10 edited images.',
      price: '$199',
    },
    {
      id: 2,
      name: 'Premium Package',
      description: 'Includes 2-hour photoshoot, 20 edited images, and a printed album.',
      price: '$399',
    },
    {
      id: 3,
      name: 'Deluxe Package',
      description: 'Includes 3-hour photoshoot, 30 edited images, a printed album, and a canvas print.',
      price: '$599',
    },
  ];

  const faqData = [
    {
      question: 'How do I book a photographer?',
      answer: 'You can easily book a photographer by filling out the contact form on our website or by calling us directly.',
    },
    {
      question: 'What is included in the photography packages?',
      answer: 'Our photography packages vary and can include a certain number of hours for the photoshoot, edited images, printed albums, and canvas prints. Please refer to the package details for more information.',
    },
    {
      question: 'Can I request a specific photographer?',
      answer: 'Yes, you can request a specific photographer based on their portfolio and availability. Just let us know your preference when making a booking.',
    },
  ];

  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [quoteFormData, setQuoteFormData] = useState({
    businessName: '',
    locationName: '',
    name: '',
    email: '',
    phone: '',
    pageUrl: window.location.href,
  });

  const handleQuickQuote = (businessName) => {
    setQuoteFormData((prevData) => ({
      ...prevData,
      businessName,
    }));
    setOpenQuoteModal(true);
  };

  const handleQuoteFormChange = (event) => {
    const { name, value } = event.target;
    setQuoteFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleQuoteFormSubmit = () => {
    // Send the quote form data to the specified email address
    const emailBody = `
      Business Name: ${quoteFormData.businessName}
      Location Name: ${quoteFormData.locationName}
      Name: ${quoteFormData.name}
      Email: ${quoteFormData.email}
      Phone: ${quoteFormData.phone}
      Page URL: ${quoteFormData.pageUrl}
    `;
    const mailtoLink = `mailto:lead@bumpandbabyportraits.com?subject=Quick Quote Request&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
    setOpenQuoteModal(false);
  };

  const handleHeroContactSubmit = (values) => {
    const emailBody = `
      Name: ${values.name}
      Budget: ${values.budget}
      Contact No: ${values.contactNo}
      Email: ${values.email}
      Page URL: ${window.location.href}
    `;
    const mailtoLink = `mailto:lead@bumpandbabyportraits.com?subject=Get Quotes Request&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Layout>
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-background"></div>
        <div className="hero-content">
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={12}>
              <div className="hero-text">
                <Title level={1} className="main-text">
                  Book Your Maternity Photoshoot with Us
                </Title>
                <Paragraph className="sub-text">
                  [Get Quotes From 700+ Verified Photographers]
                </Paragraph>
                <div className="social-proof">
                  <div className="satisfied-customers">
                    <img src="https://i.ibb.co/Xttdr1P/undefined.jpg" alt="Satisfied Customer" />
                    <img src="https://i.ibb.co/bFZsSTz/image-17389-3545089.jpg" alt="Satisfied Customer" />
                    <img src="https://i.ibb.co/bFZsSTz/image-17389-3545089.jpg" alt="Satisfied Customer" />
                    <span>1000+ Satisfied Customers</span>
                  </div>
                  <div className="average-rating">
                    <FontAwesomeIcon icon={faStar} className="star-icon" />
                    <FontAwesomeIcon icon={faStar} className="star-icon" />
                    <FontAwesomeIcon icon={faStar} className="star-icon" />
                    <FontAwesomeIcon icon={faStar} className="star-icon" />
                    <FontAwesomeIcon icon={faStar} className="star-icon" />
                    <span>4.9/5 Average Rating</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="contact-form-card">
                <Title level={3}>Get Quotes From Verified Photographers</Title>
                <Form layout="vertical" onFinish={handleHeroContactSubmit}>
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter your name' }]}
                      >
                        <Input placeholder="Enter your name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Budget"
                        name="budget"
                        rules={[{ required: true, message: 'Please select your budget' }]}
                      >
                        <Select placeholder="Select budget">
                          <Option value="100">$100</Option>
                          <Option value="300">$300</Option>
                          <Option value="500">$500</Option>
                          <Option value="1000">$1000</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Contact No"
                        name="contactNo"
                        rules={[{ required: true, message: 'Please enter your contact number' }]}
                      >
                        <Input placeholder="Enter contact no" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: 'Please enter your email' },
                          { type: 'email', message: 'Please enter a valid email' },
                        ]}
                      >
                        <Input placeholder="Enter email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="submit-button">
                    <Button type="primary" size="large" className="custom-button" htmlType="submit">
                      Get Quotes
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Client Reviews Section */}
      <Content className="content-wrapper">
        <div className="main-content">
          <Title level={2}>Client Reviews</Title>
          <div className="review-grid">
            {reviews.map((review) => (
              <div className="review-item" key={review.id}>
                <div className="review-header">
                  <img src={review.photo} alt={review.name} className="review-photo" />
                  <div className="review-info">
                    <h4>{review.name}</h4>
                    <div className="review-rating">
                      {[...Array(review.rating)].map((_, index) => (
                        <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
                      ))}
                    </div>
                  </div>
                </div>
                <p className="review-text">{review.review}</p>
              </div>
            ))}
          </div>
        </div>
      </Content>

      {/* Newest Maternity Photographers Section */}
      <Content className="content-wrapper">
        <div className="main-content">
          <Title level={2}>Newest Maternity Photographers</Title>
          <Row gutter={[16, 16]}>
            {newestPhotographers.map((photographer, index) => (
              <Col xs={24} sm={12} md={8} lg={4} key={photographer.id}>
                <div className="business-card">
                  <div className="business-image-wrapper">
                    <img alt={photographer.name} src={imagePool[index % imagePool.length]} className="business-image" />
                  </div>
                  <div className="business-details">
                    <Title level={4} className="business-name">{photographer.name}</Title>
                    <div className="button-group">
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={fasPhone} />}
                        href={`tel:${photographer.phone}`}
                        className="custom-button"
                      >
                        Call
                      </Button>
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                        href={photographer.website}
                        target="_blank"
                        className="custom-button"
                      >
                        Visit
                      </Button>
                    </div>
                    <div className="feature-boxes">
                      {photographer.features.map((feature) => (
                        <div key={feature} className="feature-box">
                          {feature}
                        </div>
                      ))}
                    </div>
                    <Button
                      type="secondary"
                      className="quick-quote-button custom-button"
                      onClick={() => handleQuickQuote(photographer.name)}
                    >
                      Get Quick Quote
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className="view-more-button">
            <Button type="primary" size="large">
              View More Photographers
            </Button>
          </div>
        </div>
      </Content>

      {/* Browse Location Section */}
      <Content className="content-wrapper">
        <div className="main-content">
          <Title level={2}>Browse Locations</Title>
          <Input.Search
            placeholder="Search locations"
            prefix={<FontAwesomeIcon icon={faSearch} />}
            style={{ marginBottom: '16px' }}
          />
          <Row gutter={[16, 16]} className="mobile-row">
  {locations.map((location) => (
    <Col key={location} xs={8} sm={6} md={4}>
      <Link to={`/${location.toLowerCase()}`} className="location-box">
        <div className="location-image-wrapper">
          <img src={`/images/locations/${location.toLowerCase()}.jpg`} alt={location} className="location-image" />
        </div>
        <div className="location-name">{location}</div>
      </Link>
    </Col>
  ))}
</Row>
        </div>
      </Content>

      {/* Featured Packages Section */}
      <Content className="content-wrapper">
        <div className="main-content">
          <Title level={2}>Featured Photography Packages</Title>
          <Row gutter={[16, 16]}>
            {featuredPackages.map((packageItem) => (
              <Col xs={24} sm={12} md={8} key={packageItem.id}>
                <Card className="package-card">
                  <Card.Meta title={packageItem.name} description={packageItem.description} />
                  <div className="package-price">{packageItem.price}</div>
                  <Button type="primary">Book Now</Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Content>

      {/* FAQ Section */}
      <Content className="content-wrapper">
        <div className="main-content faq-section">
          <Title level={2}>Frequently Asked Questions</Title>
          {faqData.map((faq, index) => (
            <div key={index} className="faq-item">
              <Title level={4}>{faq.question}</Title>
              <Paragraph>{faq.answer}</Paragraph>
            </div>
          ))}
        </div>
      </Content>

     {/* Newsletter Section */}
<Content className="content-wrapper">
  <div className="main-content newsletter-section">
    <Title level={2}>Stay Updated</Title>
    <Form layout="inline" className="newsletter-form" onFinish={handleNewsletterSubmit}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" className="custom-button" htmlType="submit">
          Subscribe
        </Button>
      </Form.Item>
    </Form>
  </div>
</Content>

{/* Quote Form Modal */}
<Modal
  title="Get Quick Quote"
  open={openQuoteModal}
  onCancel={() => setOpenQuoteModal(false)}
  footer={[
    <Button key="cancel" onClick={() => setOpenQuoteModal(false)}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={handleQuoteFormSubmit} className="custom-button">
      Submit
    </Button>,
  ]}
>
  <Form layout="vertical">
    <Form.Item label="Business Name">
      <Input name="businessName" value={quoteFormData.businessName} onChange={handleQuoteFormChange} />
    </Form.Item>
    <Form.Item label="Location Name">
      <Input name="locationName" value={quoteFormData.locationName} onChange={handleQuoteFormChange} />
    </Form.Item>
    <Form.Item
      label="Name"
      name="name"
      rules={[{ required: true, message: 'Please enter your name' }]}
    >
      <Input name="name" value={quoteFormData.name} onChange={handleQuoteFormChange} />
    </Form.Item>
    <Form.Item
      label="Email"
      name="email"
      rules={[
        { required: true, message: 'Please enter your email' },
        { type: 'email', message: 'Please enter a valid email' },
      ]}
    >
      <Input name="email" value={quoteFormData.email} onChange={handleQuoteFormChange} />
    </Form.Item>
    <Form.Item
      label="Phone"
      name="phone"
      rules={[{ required: true, message: 'Please enter your phone number' }]}
    >
      <Input name="phone" value={quoteFormData.phone} onChange={handleQuoteFormChange} />
    </Form.Item>
  </Form>
</Modal>
</Layout>
);
};

export default Home;