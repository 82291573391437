import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const PrivacyPolicy = () => {
  return (
    <StyledContainer maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box textAlign="center">
            <StyledHeading variant="h2" gutterBottom>
              Privacy Policy
            </StyledHeading>
            <StyledParagraph variant="body1">
              At our photography directory listing site, we value your privacy and are committed to protecting your personal information. This privacy policy outlines how we collect, use, and safeguard your data when you use our website and services.
            </StyledParagraph>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <StyledHeading variant="h4">Information We Collect</StyledHeading>
          <StyledParagraph variant="body1">
            We may collect personal information such as your name, email address, and phone number when you create an account, submit a contact form, or interact with our website. We also collect non-personal information such as your IP address, browser type, and operating system to analyze trends and improve our services.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12}>
          <StyledHeading variant="h4">How We Use Your Information</StyledHeading>
          <StyledParagraph variant="body1">
            We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on our website. We may also use your information to send you promotional emails or newsletters, but you can opt-out of these communications at any time.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12}>
          <StyledHeading variant="h4">Information Sharing and Disclosure</StyledHeading>
          <StyledParagraph variant="body1">
            We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our website and conducting our business. These service providers are obligated to keep your information confidential and secure.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12}>
          <StyledHeading variant="h4">Data Security</StyledHeading>
          <StyledParagraph variant="body1">
            We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12}>
          <StyledHeading variant="h4">Changes to This Privacy Policy</StyledHeading>
          <StyledParagraph variant="body1">
            We may update this privacy policy from time to time. Any changes will be posted on this page, and the revised version will be effective as of the date it is posted. We encourage you to review this privacy policy periodically for any updates or changes.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="center">
            <StyledParagraph variant="body1">
              If you have any questions or concerns about our privacy policy, please contact us using the information provided on our contact page.
            </StyledParagraph>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default PrivacyPolicy;