import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AboutUs = () => {
  return (
    <StyledContainer maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box textAlign="center">
            <StyledHeading variant="h2" gutterBottom>
              About Us
            </StyledHeading>
            <StyledParagraph variant="body1">
              Welcome to our photography directory listing site! We are passionate about connecting photographers with clients who appreciate their art and skills. Our mission is to provide a platform where photographers can showcase their work and clients can easily find the perfect photographer for their needs.
            </StyledParagraph>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledHeading variant="h4">Our Story</StyledHeading>
          <StyledParagraph variant="body1">
            Founded in 2010, our journey began with a simple idea: to create a comprehensive directory of talented photographers from various locations and specialties. Over the years, we have grown into a trusted resource for both photographers and clients, fostering a community that values creativity, professionalism, and collaboration.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledHeading variant="h4">Our Team</StyledHeading>
          <StyledParagraph variant="body1">
            Our team consists of photography enthusiasts, web developers, and customer support specialists who are dedicated to providing the best experience for our users. We work tirelessly to curate a diverse collection of photographers, ensuring that our listings are up-to-date and accurately represent each photographer's skills and style.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledHeading variant="h4">Our Mission</StyledHeading>
          <StyledParagraph variant="body1">
            We believe that every moment deserves to be captured beautifully. Our mission is to make it easy for clients to find the perfect photographer for their special occasions, whether it's a wedding, family portrait, or commercial project. We strive to support photographers by providing them with a platform to showcase their work and connect with potential clients.
          </StyledParagraph>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledHeading variant="h4">Our Values</StyledHeading>
          <StyledParagraph variant="body1">
            At our core, we value:
          </StyledParagraph>
          <ul>
            <li>Creativity: We celebrate the artistry and uniqueness of each photographer's work.</li>
            <li>Professionalism: We expect all photographers listed on our site to maintain high standards of professionalism and deliver exceptional results to their clients.</li>
            <li>Community: We foster a supportive community where photographers can connect, learn from each other, and grow their businesses.</li>
            <li>Customer Satisfaction: We are committed to helping clients find the perfect photographer for their needs and ensuring a smooth, enjoyable experience from start to finish.</li>
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="center">
            <StyledParagraph variant="body1">
              Thank you for choosing our photography directory listing site. We look forward to helping you discover amazing photographers and create lasting memories.
            </StyledParagraph>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default AboutUs;