import React from 'react';
import { Link } from 'react-router-dom';
import { locations } from '../data/data';
import { Row, Col, Typography, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './LocationList.css';
import { Helmet } from 'react-helmet';


const { Title } = Typography;


const LocationList = () => {
  return (
    <div className="location-list">
      <Helmet>
        <title>Photography Locations | Maternity, Newborn & More | Get Quotes in 3 Sec</title>
        <meta name="description" content="Explore the best photography locations for maternity, newborn, and baby photography. Our curated lists feature top-rated photographers who capture the beauty and essence of your growing family. Get instant quotes and book your photo session in just 3 seconds." />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Bump & Baby Photography",
            "url": "https://bumpandbabyportrait.com",
            "logo": "https://bumpandbabyportrait.com/logo.png",
            "description": "Bump & Baby Photography is a leading photography directory connecting clients with talented photographers specializing in maternity, newborn, and baby photography.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "123 Main Street",
              "addressLocality": "City",
              "addressRegion": "State",
              "postalCode": "12345",
              "addressCountry": "Country"
            },
            "telephone": "+1234567890",
            "email": "info@bumpandbabyportrait.com"
          }
        `}</script>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://bumpandbabyportrait.com"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Locations",
                "item": "https://bumpandbabyportrait.com/locations"
              }
            ]
          }
        `}</script>
      </Helmet>

      <div className="content-wrapper">
        <div className="main-content">
          <Title level={2}>Browse Locations</Title>
          <Input.Search
            placeholder="Search locations"
            prefix={<FontAwesomeIcon icon={faSearch} />}
            style={{ marginBottom: '16px' }}
          />
          <Row gutter={[16, 16]} className="mobile-row">
            {locations.map((location) => (
              <Col key={location} xs={8} sm={6} md={4}>
                <Link to={`/${location.toLowerCase()}`} className="location-box">
                  <div className="location-image-wrapper">
                    <img src={`/images/locations/${location.toLowerCase()}.jpg`} alt={location} className="location-image" />
                  </div>
                  <div className="location-name">{location}</div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LocationList;